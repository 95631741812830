if (typeof Object.assign !== "function") {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) {
      // .length of function is 2
      "use strict";
      if (target === null || target === undefined) {
        throw new TypeError("Cannot convert undefined or null to object");
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}

// Require the polyfill before requiring any other modules.
require("intersection-observer");

try {
  window.$ = window.jQuery = require("jquery");

  require("bootstrap");
  require("./jquery.cycle2.min.js");
  require("magnific-popup");
} catch (e) {}

window.ScrollMagic = require("scrollmagic");

// import fontawesome icons
import { library, dom } from "@fortawesome/fontawesome-svg-core";
// import pro solid icons
import { faVideo } from "@fortawesome/pro-solid-svg-icons/faVideo";
import { faComment } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faNewspaper } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faArrowCircleLeft } from "@fortawesome/pro-solid-svg-icons/faArrowCircleLeft";
import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons/faArrowCircleRight";
import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faQuoteLeft } from "@fortawesome/pro-solid-svg-icons/faQuoteLeft";
import { faQuoteRight } from "@fortawesome/pro-solid-svg-icons/faQuoteRight";
// import pro regular icons
import { faLongArrowAltLeft } from "@fortawesome/pro-regular-svg-icons/faLongArrowAltLeft";
import { faLongArrowAltRight } from "@fortawesome/pro-regular-svg-icons/faLongArrowAltRight";
import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons/faCalendarCheck";
import { faShieldAlt } from "@fortawesome/pro-regular-svg-icons/faShieldAlt";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons/faSyncAlt";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
// import free brands icons
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons/faXTwitter";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";

// add the imported icons to the library
library.add(
  faVideo,
  faComment,
  faPhone,
  faNewspaper,
  faSearch,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowRight,
  faQuoteLeft,
  faQuoteRight,
  faBars,
  faTimesCircle,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faCalendarCheck,
  faShieldAlt,
  faSyncAlt,
  faTimes,
  faAngleDown,
  faFacebookF,
  faTwitter,
  faXTwitter,
  faYoutube,
  faInstagram
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// import lozad
import lozad from "lozad";

const observer = lozad(".lozad", {
  load: function(el) {
    el.src = el.dataset.src;
    el.onload = function() {
      el.classList.add("fade-in");
    };
  },
}); // lazy loads elements with default selector as '.lozad'
observer.observe();

import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();
