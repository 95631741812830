require('./bootstrap');

$('.accordion').collapse();

$('.carousel').each(function() {
    $(this).carousel({
        interval: 99999999
    });

    var total = $(this).find('.item').length;
    // console.log(total);

    var currentIndex = $(this).find('div.active').index() + 1;
    // console.log(currentIndex);

    $(this).find('.slider__pageCount').html(currentIndex + ' of '  + total);

    $(this).on('slid.bs.carousel', function (e) {

        currentIndex = $(this).find('div.active').index() + 1;

        // Now display this wherever you want
        var text = currentIndex + ' of ' + total;
        $(this).find('.slider__pageCount').html(text);
    });

    // var total = $(this).find('.item').length;
    // var currentIndex = $(this).find('div.active').index() + 1;
    // $(this).find('.slider__pageCount').html(currentIndex + ' of '  + total);

// This triggers after each slide change
//     $('.carousel').on('slid.bs.carousel', function () {
//         currentIndex = $('div.active').index() + 1;
//
//         // Now display this wherever you want
//         var text = currentIndex + ' of ' + total;
//         $('.slider__pageCount').html(text);
//     });
})


$(document).ready(function () {
    $win = $(window);
    // initExpandSearch();
    initRevealGlobalSearch();
    initHomeAlert();
    initDonateMenu();
    initSafetyTab();
    initSecondaryNav();
    initLandingPage();
    initMediaLibrary();
    // initContactModal(); disabled - see comment below
    initInterior();
    initListView();
	if ( $('#pager').get(0) ) initLoadingMore();
    if ($('#freeform_approximate_number_of_participants').get(0)){
        var $el = $('#freeform_approximate_number_of_participants');
        $el.prop('type','number');
        $el.attr('min', '5');
    }

    if ($("#freeform_phone").get(0)){
        document.getElementById('freeform_phone').addEventListener('input', function (e) {
          var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          e.target.value = !x[2] ? x[1] : + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
    }

});

/*document.getElementById('freeform_phone').addEventListener('input', function (e) {
  var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2] ? x[1] : + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
});*/



$(document).on('submit', 'form', function(e){
    var $this = $(this);
    var $el = $('#freeform_approximate_number_of_participants', $this);
    if ($el.get(0)){
       $val = $el.val();
        if ($val !== '' && ( isNaN($val) || parseInt($val) < 5 )){
            alert('Must have greater than 5 participants');
            $el.val('');
            $el.focus();
            return false
        }
    }
    return true;
});


$(document).on( 'click', '#grid .item', function(e) {
	e.preventDefault();
	var $mfp_src = $(this).attr('data-mfp-src');
	if ( typeof $mfp_src == 'undefined' || e.target.tagName.toUpperCase() != "A"){		
		var $a = $('a.grid-click', this);
		var $href = $a.attr('href');		
		if ( typeof $mfp_src == 'undefined' ) {
			if ( $a.attr('target') == '_blank' ) {
				var win = window.open($href, '_blank');
  				win.focus();
			}
			else
				window.location = $href;
		}
		else
			$a[0].click();
	}
	return false;
});

// this has been disabled...old search animation
function initExpandSearch() {
    $('#header .search').hover(function () {
        var text = $('#header .search .form-text').val();
        var $searchBox = $('#header .search');
        var $textBox = $('#header .search .form-group');
        var $textInput = $('#header .search .form-control');
        var $navMenu = $('#header .utility-nav .menu');
        if (text == '') {
            if ($searchBox.hasClass('expand')) {
                $searchBox.animate({ width: '22px' }, 400);
                $textBox.animate({ width: '11px' }, 400);
                $textInput.animate({ width: '11px' }, 400);
                $navMenu.animate({ marginLeft: '33.33333333%' });
            } else {
                $searchBox.animate({ width: '402px' }, 400);
                $textBox.animate({ width: '402px' }, 400);
                $textInput.animate({ width: '393px' }, 400);
                $navMenu.animate({ marginLeft: 0 });
            }
            $searchBox.toggleClass('expand');
            return false;
        }
    });
}

// Reveal global search bar in header
function initRevealGlobalSearch() {
    $('.global-search-toggle').on('click', function() {
        $('.global-search-toggle .fa-search, .global-search-toggle .fa-times').toggleClass('hidden');
        $('.global-search').slideToggle();
    });
}

function initHomeAlert() {
    
    $("#alerts-events .callout").hover(function () {
        $(this).siblings(".background").find("img").toggleClass("desaturate");
    });

    var winWidth = $win.width();
    if (winWidth > 1600) {
        var alertImgWidth = winWidth / 2;
        $("#alerts-events .column .background img").each(function () {
            $(this).width(alertImgWidth + 'px');
        });
    }
    $win.resize(function () {
        winWidth = $win.width();
        if (winWidth > 1600) {
            $("#alerts-events .column .background img").each(function () {
                $(this).width(alertImgWidth + 'px');
            });
        }
    });

}

function initDonateMenu() {

    if (document.getElementById('donate-menu-wrapper')) {
        var controller = new ScrollMagic.Controller();
        new ScrollMagic.Scene({ triggerElement: "#donate-menu-wrapper", triggerHook: 'onLeave', offset: -104 })
            .setClassToggle("#donate-menu", "collapsed")
            //.setPin("#donate-menu")
            .addTo(controller);
    }

}

function initSafetyTab() {

    $('.internet-safety__btn-open, .internet-safety__btn-close').on('click', function() {
        $('.anchor-menu__backToTopBtn').toggleClass('-pull-up')
        $('.internet-safety').toggleClass('open');
    });

    // $("#internet-safety").hover(function () {
    //     var $safetyTab = $("#internet-safety");
    //     var $safetyCaption = $("#internet-safety .caption");
    //     if ($safetyTab.hasClass('expanded')) {
    //         $safetyCaption.animate({ left: '-144px' }, 400);
    //     } else {
    //         $safetyCaption.animate({ left: '0' }, 400);
    //     }
    //     $safetyTab.toggleClass('expanded');
    //     return false;
    // });
}

function initSecondaryNav() {

    /*if ($("#sidebar-one nav").get(0)) {
        $("#sidebar-one nav ul > li").each(function () {
            $(this).has("ul").find("> a").append('<i class="fa fa-plus-circle icon"></i>');
        });
    }

    $("#sidebar-one nav .icon").on('click', function () {
        console.log('click');
        
        if ($(this).hasClass('fa-minus-circle')) {
            $(this).addClass('fa-plus-circle').removeClass('fa-minus-circle');
        } else {
            $(this).addClass('fa-minus-circle').removeClass('fa-plus-circle');
        }
        
        $(this).parent('a').siblings("ul").toggle(400);

        return false;
    });*/

    $("#mobile-nav-secondary-toggle").on('click', function () {
        $("#sidebar-one nav").slideToggle('fast').toggleClass('open');
    });

}

function initLandingPage() {

    $("#grid .item.landing").hover(function () {
        $(this).find(".image img").toggleClass('desaturate');
    });

}

function loadMore() {
	if ( $('#stories-container').get(0) ) {
		$('#stories-container .row.hidden').slice(0,2).removeClass('hidden');
	}
	if ( $('#media-container').get(0) ) {
		$('#media-container .row.hidden').slice(0,2).removeClass('hidden');
	}
	initLoadingMore();
}

function initLoadingMore() {
	if ( $('#stories-container .row.hidden,#media-container .row.hidden').length > 0 )
	{
		$('#pager').show();
		window.onscroll = matts_scroll;
	}
	else
	{
		$('#pager').hide();
		window.onscroll = null;
	}
}

function matts_scroll()
{
	var $elem = $('#pager');
    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();
	if ( (elemBottom <= docViewBottom) && (elemTop >= docViewTop) ) {
		loadMore();
	}
}

function initMediaLibrary() {

    if ($('#grid .item.photos').get(0)) {
        // console.log('photos are here');
        $('#grid .item.photos').each(function () {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        });
    }

    $('#grid .item.video').magnificPopup({ type: 'iframe' });
    $('.entry--video').magnificPopup({ type: 'iframe' });
}

/**
 * For some reason the previous dev added padding
 * to the header when the contact modal is open.
 * Let's disable it for now...cuz it's just weird.
 */
function initContactModal() {

    $('#contactModal').on('show.bs.modal', function (e) {
        
        if ($win.width() > 768) {
            // console.log('pad');
            $("#header").css("padding-right", "17px");
        }
    });

    $('#contactModal').on('hide.bs.modal', function (e) {
        if ($win.width() > 768) {
            $("#header").css("padding-right", "0px");
        }
    });


}

function initInterior() {

    if ($("#main-interior-background").get(0)) {

        var bgOffsetDim = $("#action-menu").offset();
        // console.log(bgOffsetDim.left);
        $("#main-interior-background .background img").css("width", bgOffsetDim.left + "px");

        $win.resize(function () {
            bgOffsetDim = $("#action-menu").offset();
            $("#main-interior-background .background img").css("width", bgOffsetDim.left + "px");
        });

    }

}

function initListView() {
    if ($("#list-view").get(0)) {
        $("#list-view .photos").each(function () {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        });
        $("#list-view .video").each(function () {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'iframe'
            });
        });
    }
}

$(document).on('click', '#mobile-nav-toggle', function (e) {
    e.preventDefault();
    $("#header .mobile-nav").slideToggle('fast');
});

$(document).on('click', '.story-filter', function(e){
	e.preventDefault();
	
	var url = '/stories/grid-all', filter = $(this).attr('data-filter'), state = $(this).attr('data-state'), $cont = $('#stories-container');
	
	$('.story-filter').attr('data-state', 'off');
	if ( typeof state == 'undefined' || state == 'off' ){
		url = '/stories/grid-' + filter;
		$(this).attr('data-state', 'on');
	}
	
	$.get( url, function( dt ) {
		$cont.html( dt );
		initLoadingMore();
	});
	
	return false;
});

$(document).on('click', '.media-filter', function(e){
	e.preventDefault();
	
	var url = '/media-library/grid-all', filter = $(this).attr('data-filter'), state = $(this).attr('data-state'), $cont = $('#media-container');
	
	$('.media-filter').attr('data-state', 'off');
	if ( typeof state == 'undefined' || state == 'off' ){
		url = '/media-library/grid-' + filter;
		$(this).attr('data-state', 'on');
	}
	
	$.get( url, function( dt ) {
		$cont.html( dt );
		initLoadingMore();
		initMediaLibrary();
	});
	
	return false;
});

$(document).on('submit', '#form-search-news', function(e){
    if (e.preventDefault) { e.preventDefault(); } else { e.returnValue = false; }
	
    var values = $(this).serialize(), postURL = $(this).attr('action'), $cont = $('#media-container');

    // console.log(values);
    
	
	$.ajax({
		url: postURL,
		type: "POST",
		data: values,
		success: function(dt, textStatus, jqXHR){
			$cont.html( dt );
			initLoadingMore();
		}
	});
});

$(document).on('click', '#home-carousel .slider .item', function (e) {
    var pageLink = $(this).find('.cta').attr('href');
    window.location.href = pageLink;
});

$(document).on('click', '#stats-news .callout.news', function (e) {
    var pageLink = $(this).find('.btn').attr('href');
    window.location.href = pageLink;
});

$(document).on('click', '#alerts-events .callout', function (e) {
    var pageLink = $(this).find('.cta').attr('href');
    window.location.href = pageLink;
});

$(document).on('click', '#stories-container .column', function (e) {
    e.preventDefault();
    window.location = $(this).attr("data-href");
    return false;
});

$(document).on('click', '#contact-pop-up-submit', function (e) {
    e.preventDefault();
    var $form = $('#contact-pop-up-form');
    /*var confirmed = '';
    $('.lightbox .holder .error').removeClass('error');
    var $required_selects = [
		{ id: 'freeform_state', label: 'select your state*', label_es: 'select your state*' }
    ];
    $.each($required_selects, function (i, item) {
        var $inp = $form.find('select[id="' + item.id + '"]');
        var spn_txt = $inp.parent().find('span.center').text();
        if (spn_txt == item.label || spn_txt == item.label_es) {
            $inp.prop('selectedIndex', -1);
        }
    });*/
    $.post(
         $form.attr('action'),
         $form.serialize(),
         function (data) {
             if (data.success == false) {
                 $.each(data.errors, function (i, item) {
                     //if (item == 'You must submit the word that appears in the image')
                         //$('#newsletter-pop-up-form input[name="captcha"]').parent().addClass('error');

                     $('#contact-pop-up-form [name="' + i + '"]').parent().addClass('error');
                     var $errorHolder = $('[name="' + i + '"]').parent().find('.error_message');
                     var error = ($.isArray(item) ? item.join('<br/>') : item);

                     if ($errorHolder.length > 0) {
                         $errorHolder.append('<p>' + error + '</p>').show();
                     }
                     else {
                         $('#contact-pop-up-form .error-box').show();
                     }
                 });
             }
             else if (data.success) {
                 confirmed = data['return_url'];
                 $.get(confirmed, function (data) {
                     $('#contactModal .modal-dialog .modal-body').html(data);
                     window.location = "#top";
                 });
             }
         }
     );

    return false;
});

$(document).on('click', '#global-search-submit', function (e) {
    var searchString = $("#global-search input[name=keywords]").val();
    if (!searchString) {
        return false;
    }
});

// Smooth scroll
window.onload = (event) => {
    const anchor = Array.prototype.slice.call(document.querySelectorAll('[data-scroll]'), 0);

    if (anchor.length) {
        anchor.forEach(el => {
            el.addEventListener('click', event => {
                const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
                event.preventDefault();

                const targetID = el.getAttribute('data-scroll');
                const targetAnchor = document.getElementById(targetID);

                if (!targetAnchor) return;

                const originalTop = distanceToTop(targetAnchor) - 275;
                window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
            }, false);
        });
    }
};

// Anchor menu back to top button
$(window).scroll(function() {
    if ($(this).scrollTop() > 768) { // this refers to window
        $('.anchor-menu__backToTopBtn').addClass('-visible');
    } else {
        $('.anchor-menu__backToTopBtn').removeClass('-visible');
    }
});

/**
 * Counter
 */
let counter = function (el) {
    countTo = $(el).attr('data-count');

    $({
        countNum: $(el).text()
    }).animate({
            countNum: countTo
        },

        {
            duration: 2400,
            easing: 'linear',
            step: function () {
                $(el).text(commaSeparateNumber(Math.floor(this.countNum)));
            },
            complete: function () {
                $(el).text(commaSeparateNumber(this.countNum));
                $(el).removeAttr('data-count');
            }
        });
};

let commaSeparateNumber = function (val) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
    }
    return val;
}

/**
 * AOI - Animate on intersection
 * 
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */

let callback = function (entries, observer) {
    entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
            entry.target.classList.add('o-fade-in');

            if (entry.target.hasAttribute('data-count')) {
                counter(entry.target);
            }
        } else {
            entry.target.classList.remove('o-fade-in');
        }
    });
};

let observer = new IntersectionObserver(callback);

let targets = document.querySelectorAll("[data-aoi], [data-count]");

targets.forEach(target => {
    observer.observe(target);
});